exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lg-elvis-js": () => import("./../../../src/pages/lg/elvis.js" /* webpackChunkName: "component---src-pages-lg-elvis-js" */),
  "component---src-pages-lg-neo-js": () => import("./../../../src/pages/lg/neo.js" /* webpackChunkName: "component---src-pages-lg-neo-js" */),
  "component---src-pages-lg-wallet-lines-js": () => import("./../../../src/pages/lg/wallet-lines.js" /* webpackChunkName: "component---src-pages-lg-wallet-lines-js" */),
  "component---src-pages-page-about-js": () => import("./../../../src/pages/page/about.js" /* webpackChunkName: "component---src-pages-page-about-js" */),
  "component---src-pages-page-careers-js": () => import("./../../../src/pages/page/careers.js" /* webpackChunkName: "component---src-pages-page-careers-js" */),
  "component---src-pages-redirect-js": () => import("./../../../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-tracking-js": () => import("./../../../src/pages/tracking.js" /* webpackChunkName: "component---src-pages-tracking-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-organic-js": () => import("./../../../src/templates/post-organic.js" /* webpackChunkName: "component---src-templates-post-organic-js" */),
  "component---src-templates-sub-category-js": () => import("./../../../src/templates/sub-category.js" /* webpackChunkName: "component---src-templates-sub-category-js" */)
}

